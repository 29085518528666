<template>
  <header
    :class="{ '-scrolled': scrolledDown && !isMenuOpen }"
    class="header site-header"
  >
    <div class="inner" :class="{ '-open': isMenuOpen }">
      <Hamburger
        :class="{ '-open': isMenuOpen }"
        class="menutoggle"
        @click="toggleMenu()"
      />
      <ZuehlkeHomeLink class="logo" :class="{ '_hidden-vs': isMenuOpen }" />
      <LangSwitch class="toggles" :class="{ '_hidden-vs': !isMenuOpen }" />
      <MainNavigation :is-open="isMenuOpen" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import ZuehlkeHomeLink from '~/components/site-header/components/ZuehlkeHomeLink.vue';
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import Hamburger from '~/components/site-header/components/HamburgerMenu.vue';

const isMenuOpen = ref<boolean>(false);

const { y: scrollY } = useWindowScroll();
const scrolledDown = ref<boolean>(false);
let lastScrollY = 0;

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;

  toggleLock(isMenuOpen.value);
}

watch(scrollY, (newY) => {
  // watch if the user has scrolled down and is still scrolling down
  scrolledDown.value = newY > 50 && newY > lastScrollY;
  lastScrollY = newY;
});

function toggleLock(lock: boolean) {
  if (lock) {
    document.body.style.overflowX = 'clip';
    document.body.style.overflowY = 'clip';
  } else {
    document.body.style.overflowX = 'var(--base-overflow-x)';
    document.body.style.overflowY = 'unset';
  }
}
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transform: translate(0, 0);
  transition:
    transform 200ms,
    background-color var(--base-transition-duration-slow)
      var(--base-transition-easing),
    color var(--base-transition-duration-slow) var(--base-transition-easing);
  background-color: var(--base-color-background);
  color: var(--base-color-foreground);

  &.-scrolled {
    transform: translate(0, -100%);
  }

  > .inner {
    --size-logo: 5.5rem;

    position: relative;
    display: grid;
    grid-template-rows: repeat(2, 2.75rem);
    grid-template-columns: var(--size-logo) 1fr minmax(0, auto);
    max-width: var(--site-max-width);
    margin: 0 auto;

    @media (--vs) {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0 1.25rem;
      height: 0;

      &.-open {
        height: 100svh;
        overflow-y: auto;
      }
    }

    @media (--vl) {
      padding: 0 var(--base-component-padding-inline);
    }

    > .menutoggle {
      position: absolute;
      right: 1.25rem;
      top: calc(var(--size-logo) / 2);
      transform: translateY(-50%);
      z-index: var(--z-header);

      @media (--vs) {
        display: block;
      }

      @media (--vl) {
        display: none;
      }
    }

    > .logo {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      height: var(--size-logo);
      width: var(--size-logo);

      @media (--vs) {
        &::after {
          content: '';
          position: absolute;
          inset: 0;
          width: 100%;
          height: var(--size-logo);
          background-color: var(--color-white);
          z-index: -1;
        }
      }
    }

    > .toggles {
      grid-row: 1 / 3;
      grid-column: 4 / 5;
      align-self: flex-end;
      margin-left: 2rem;
      position: relative;

      @media (--vs) {
        order: 1;
      }

      @media (--vl) {
        &::after {
          content: '';
          width: 0.0625rem;
          height: 1rem;
          opacity: 0.1;
          background: var(--color-topaz-800);
          display: block;
          position: absolute;
          inset: 1.25rem auto auto -1rem;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
